import { useContext, useEffect, useRef, useState } from "react";
import Chat from "./Chat";
import { chatActions, getChatHistory, postChat } from "./utils";
import SlideInModal from "../../../common/SlideInModal/SlideInModal";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import ChatHistory from "./ChatHistory";
import useIsMobile from "../../../../hooks/useIsMobile";

export interface ChatMessage {
  role: "assistant" | "user" | "system";
  content: string;
}

const baseMessage =
  "Hi, I’m the Topline Pro Assistant. Using AI, I can help answer your questions and take actions for you. If you ever want to talk to your account manager, just let me know. Otherwise, how can I help you today?";

export default function ChatbotModal({ isOpen, setIsOpen }: any) {
  const isMobile = useIsMobile();
  const { profileInfo } = useContext(DesktopContext);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [conversationId, setConversationId] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [action, setAction] = useState<any>(null);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [history, setHistory] = useState([]);
  const [initialMessage, setInitialMessage] = useState(baseMessage);
  const [attachedPhoto, setAttachedPhoto] = useState("");
  const [botType, setBotType] = useState(null);

  useEffect(() => {
    if (profileInfo?.first_name) {
      setInitialMessage(
        `Hi ${profileInfo.first_name}, I’m the Topline Pro Assistant. Using AI, I can help answer your questions and take actions for you. If you ever want to talk to your account manager, just let me know. Otherwise, how can I help you today?`
      );
    }
  }, [profileInfo?.first_name]);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  function scrollToBottom() {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  }

  async function handleSend(message: ChatMessage) {
    setAction(null);
    setLoading(true);

    const updatedMessages = [...messages, message];

    setMessages(updatedMessages);

    const responseData = await postChat(
      updatedMessages,
      conversationId,
      botType
    );
    if (!responseData) {
      setLoading(false);
      scrollToBottom();
      return;
    }

    const response = responseData?.response;
    const action = responseData?.action;

    setConversationId(responseData?.conversation_id);
    setLoading(false);
    setBotType(responseData?.bot_type);

    setMessages((messages) => [
      ...messages,
      {
        role: "assistant",
        content: response,
      },
    ]);

    if (action) {
      const actionObj = chatActions.find((a) => a.description === action);
      setAction(actionObj);
    }
  }

  function handleReset() {
    setAction(null);
    setLoading(false);
    setConversationId(null);
    setShowChatHistory(false);
    setBotType(null);
    setAttachedPhoto("");

    setMessages([
      {
        role: "system",
        content: "You are an AI assistant.",
      },
      {
        role: "assistant",
        content: initialMessage,
      },
    ]);
  }

  function showHistory() {
    setShowChatHistory(true);
  }

  function selectConversation(message: any) {
    setConversationId(message?.id);
    const allMessages = message?.messages?.map((m) => {
      return {
        role: m.role,
        content: m.message,
      };
    });
    setMessages(allMessages);
    setShowChatHistory(false);
  }

  function onCloseModal() {
    if (showChatHistory) {
      setShowChatHistory(false);
      return;
    }
    setIsOpen(false);
    handleReset();
  }

  function getHeader() {
    if (showChatHistory) {
      return "Conversation History";
    }
    return "Topline Pro Assistant";
  }

  useEffect(() => {
    async function getHistory() {
      const chatHistory = await getChatHistory();
      setHistory(chatHistory);
    }
    getHistory();
  }, [messages, isOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setMessages([
      {
        role: "system",
        content: "You are an AI assistant.",
      },
      {
        role: "assistant",
        content: initialMessage,
      },
    ]);
  }, []);

  return (
    <SlideInModal
      open={isOpen}
      onCloseFunction={onCloseModal}
      header={getHeader()}
      showDesktopLeftArrow={!isMobile && showChatHistory}
    >
      {!showChatHistory && (
        <Chat
          setIsOpen={setIsOpen}
          messages={messages}
          loading={loading}
          setLoading={setLoading}
          onSend={handleSend}
          onReset={handleReset}
          showHistory={showHistory}
          action={action}
          attachedPhoto={attachedPhoto}
          setAttachedPhoto={setAttachedPhoto}
          messagesEndRef={messagesEndRef}
        />
      )}

      {showChatHistory && (
        <div className="flex flex-col space-y-2 p-4">
          {history?.map((message: any) => {
            return (
              <ChatHistory
                key={message?.id}
                message={message}
                selectConversation={selectConversation}
              />
            );
          })}
        </div>
      )}
    </SlideInModal>
  );
}
