import React, { createContext, useContext, useState } from "react";

import {
  AppContext,
  NavigationFrame,
} from "../AppContextProvider/AppContextProvider";

interface MobileContextType {
  handleMobileMenuOpen: (isOpen: boolean) => void;
  /**
   * Toggle controlling the mobile menu opening and closing
   */
  mobileMenuOpen: boolean;
  handleSelectedFrame: (name: string) => void;
  selectedFrame: Array<NavigationFrame>;
  bottomLeftNavigation: Array<NavigationFrame>;
  setHelpModal: (isOpen: boolean) => void;
  isMobileNavHeaderOpen: boolean;
  setIsMobileNavHeaderOpen: (isOpen: boolean) => void;
  isMobileNavbarVisible: boolean;
  setMobileNavbarVisibility: (isOpen: boolean) => void;
}
export const MobileContext = createContext({} as MobileContextType);

export const MobileContextProvider = ({ children }: any) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const {
    selectedFrame,
    handleSelectedFrame,
    bottomLeftNavigation,
    setHelpModal,
  } = useContext(AppContext);
  const [isMobileNavHeaderOpen, setIsMobileNavHeaderOpen] = useState(true);
  // To toggle navbar visibility when already in a mobile context. E.g. don't use this to toggle visibilty in desktop mode.
  const [isMobileNavbarVisible, setMobileNavbarVisibility] = useState(true);

  function handleMobileMenuOpen(isOpen: boolean) {
    isOpen ? setMobileMenuOpen(false) : setMobileMenuOpen(true);
  }

  const MobileContextValue: MobileContextType = {
    handleMobileMenuOpen,
    handleSelectedFrame,
    selectedFrame,
    mobileMenuOpen,
    setHelpModal,
    bottomLeftNavigation,
    isMobileNavHeaderOpen,
    setIsMobileNavHeaderOpen,
    isMobileNavbarVisible,
    setMobileNavbarVisibility,
  };
  return (
    <MobileContext.Provider value={MobileContextValue}>
      {children}
    </MobileContext.Provider>
  );
};
