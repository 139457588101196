import { api } from "../../../helpers/topline-api";

const getCustomerByAny = async (searchString: string) => {
  const response = await api.post(`/api/get-customer-by-any`, {
    search_key: searchString,
  });
  return response.data;
};

export const debounceGetCustomerByAny = async (searchString: string) => {
  let timer: any;
  return new Promise((resolve) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const response = await getCustomerByAny(searchString);
      resolve(response);
    }, 1000);
  });
};

const getLandingPageByAny = async (searchString: string) => {
  const response = await api.post(`/api/get_landing_page_by_any`, {
    search_key: searchString,
  });
  return response.data;
};

export const debounceGetLandingPageByAny = async (searchString: string) => {
  let timer: any;
  return new Promise((resolve) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const response = await getLandingPageByAny(searchString);
      resolve(response);
    }, 1000);
  });
};

export const updateCustomerLandingPage = async (data: any) => {
  const response = await api.post(`/api/update`, data);
  return response.data;
};
