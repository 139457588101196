import { useRouter } from "next/router";
import { api } from "../../helpers/topline-api";
import { useEffect } from "react";
import { AxiosError, AxiosResponse } from "axios";

const AuthGuardInterceptor: React.FC = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse<unknown>) => {
      return response;
    };

    const errInterceptor = (error: AxiosError<unknown>) => {
      if (error?.response?.status === 401) {
        console.error(
          "Axios interceptor received a 401 - signing the user out."
        );

        router.push(`/signout?redirect=${router.asPath}`);
      }

      return Promise.reject(error);
    };

    const interceptor = api.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => api.interceptors.response.eject(interceptor);
  }, []);

  return <>{children}</>;
};

export { AuthGuardInterceptor };
